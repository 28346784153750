import React, { lazy } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
// import LoadingPlaceholder from '../components/LoadingPlaceHolder';
import Layout from "../components/Layout";
import RouteWrapper from "./RouteWrapper";
import { withStyles } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import { useStyles } from "../components/Layout/Styles";
import { LinkedInCallback } from "react-linkedin-login-oauth2";
import PaymentPage from "../pages/payment";
import Advisorypage from "../pages/advisory";

export const NamedRoutes = {
  Landing: "/",
  subs: "/subscribtion",
  SignIn: "/signin",
  SignUp: "/signup",
  LinkCB: "/linkedin",
  ForgotPassword: "/forgot-password",
  Home: "/home",
  myAcquisition: "/my-acquisition",
  Settings: "/settings",
  marketPlace: "/marketplace",
  StartupDetails: "/startup-details/:companyId",
  Profile: "/profile",
  FavoriteList: "/favorite-list",
  MyListing: "/my-listing",
  Linkedin: "/linkedin",
  Acquisition: "/acquisition/:companyId",
  VDR: "/VDR",
  Payment: "/payment",
  Advisory: "/advisory",
  Draft: "/draft-details/:companyId",
};
const BaseScreen = lazy(() => import("../pages/BaseScreen"));
const Signin = lazy(() => import("../pages/Auth/SignIn"));
const Signup = lazy(() => import("../pages/Auth/SignUp"));
const ForgotPassword = lazy(() => import("../pages/Auth/ForgotPassword"));
const Home = lazy(() => import("../pages/Home"));
const LinkedIn = lazy(() => import("../pages/linkedin"));

const Settings = lazy(() => import("../pages/marketPlace"));
const MyListing = lazy(() => import("../pages/MyListing"));
// const MyListing = lazy(() => import("../pages/MyListing"));
const MyAcquisition = lazy(() => import("../pages/MyAcquisition"));
const StartupDetails = lazy(() => import("../pages/StartupDetails"));
const Profile = lazy(() => import("../pages/Profile"));
const FavoriteList = lazy(() => import("../pages/favoriteList"));
const VDR = lazy(() => import("../pages/VDR"));
const Payment = lazy(() => import("../pages/payment"));
const Draft = lazy(() => import("../pages/Draft"));

const AppRoutes = () => (
  <BrowserRouter>
    <React.Suspense fallback={<></>}>
      <Switch>
        {/* <Route exact path={} component={Signin} /> */}
        {/* <Route exact path={NamedRoutes.SignUp} component={SignUp} />
        <Route exact path={NamedRoutes.Otp} component={Otp} />
        <Route
          exact
          path={NamedRoutes.ForgetPassword}
          component={ForgetPassword}
        />
     <PrivateRoute exact path={NamedRoutes.Checkout} component={Checkout} /> */}
        <Route path="/" component={BaseScreen} exact />

        <RouteWrapper
          exact
          layout={Layout}
          path={NamedRoutes.SignIn}
          component={Signin}
        />
        <RouteWrapper
          exact
          layout={Layout}
          path={NamedRoutes.LinkCB}
          component={LinkedInCallback}
        />
         <RouteWrapper
          exact
          layout={Layout}
          path={NamedRoutes.Linkedin}
          component={LinkedIn}
        />
        <RouteWrapper
          exact
          layout={Layout}
          path={NamedRoutes.SignUp}
          component={Signup}
        />
        <RouteWrapper
          exact
          layout={Layout}
          path={NamedRoutes.ForgotPassword}
          component={ForgotPassword}
        />
        <RouteWrapper
          exact
          layout={Layout}
          path={NamedRoutes.Landing}
          component={BaseScreen}
        />
        <RouteWrapper
          exact
          layout={Layout}
          path={NamedRoutes.Home}
          component={Home}
        />
        <RouteWrapper
          exact
          layout={Layout}
          path={NamedRoutes.Advisory}
          component={Advisorypage}
        />
        <RouteWrapper
          exact
          layout={Layout}
          path={NamedRoutes.Draft}
          component={Draft}
        />
        <RouteWrapper
          exact
          layout={Layout}
          path={NamedRoutes.subs}
          component={PaymentPage}
        />
        <RouteWrapper
          exact
          layout={Layout}
          path={NamedRoutes.Profile}
          component={Profile}
        />

        <RouteWrapper
          path={NamedRoutes.marketPlace}
          component={Settings}
          layout={Layout}
        />

        <RouteWrapper
          path={NamedRoutes.StartupDetails}
          component={StartupDetails}
          layout={Layout}
        />

        {/* <RouteWrapper
          path={NamedRoutes.Profile}
          component={Profile}
          layout={Layout}
        /> */}
        <RouteWrapper
          exact
          path={NamedRoutes.MyListing}
          component={MyListing}
          layout={Layout}
        />

        <RouteWrapper
          exact
          path={NamedRoutes.Acquisition}
          component={MyAcquisition}
          layout={Layout}
        />
        <RouteWrapper
          exact
          path={NamedRoutes.myAcquisition}
          component={MyListing}
          layout={Layout}
        />
        <RouteWrapper
          path={NamedRoutes.FavoriteList}
          component={FavoriteList}
          layout={Layout}
        />
        <RouteWrapper
          exact
          layout={Layout}
          path={NamedRoutes.VDR}
          component={VDR}
        />
        <RouteWrapper
          exact
          layout={Layout}
          path={NamedRoutes.Payment}
          component={Payment}
        />
      </Switch>
    </React.Suspense>
  </BrowserRouter>
);

//@ts-ignore
export default withRouter(withStyles(useStyles)(AppRoutes));
