export const formatDate = (date: string): string =>
  new Date(date).toLocaleDateString('us', {
    year: 'numeric',
    month: 'short'
  });
let monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];
export const formatDateString = (date: string): string => {
  let formattedDate = date.split('T');
  let dateFormatted = formattedDate[0].split('-');
  let timeFormatted = formattedDate[1].split(':');
  let AM = parseInt(timeFormatted[0]) > 12 ? 'PM' : 'AM';
  let dayInNumbers = parseInt(dateFormatted[2]);
  let days =
    dayInNumbers === 1 || dayInNumbers === 21 || dayInNumbers === 31
      ? `${dayInNumbers}st`
      : dayInNumbers === 2 || dayInNumbers === 22
      ? `${dayInNumbers}nd`
      : dayInNumbers === 3 || dayInNumbers === 23
      ? `${dayInNumbers}rd`
      : `${dayInNumbers}th`;
  days += ' of';
  let month = monthNames[parseInt(dateFormatted[1]) -1 ];

  return `${days} ${month} ${dateFormatted[0]} ${timeFormatted[0]}:${timeFormatted[1]} ${AM}`;
};

