import { useHistory } from "react-router-dom";
import PricingCardo from "../../components/PricingModal/Card";
// import { Button } from "../../components/PricingModal/Card/styled";
import { Button } from "@material-ui/core";
import Flex from "../../components/flex";
import { Wrapper } from "./styled";
import { useContext, useEffect, useState } from "react";
import UserStore from "../../store/userContext/context";
import { get } from "../../utils/request";
const plans = [
  {
    title: "Onlooker",
    id:"",
    price: 0,
    features: [
      "Browse the marketplace to see non-premium and starter businesses.",
      "Access to knowledge Hub resources for non-premium members.",
      "Limited filtration access on marketplace.",
      "Best fit if you want to browse the platform before starting your journey.",
    ],
  },
  {
    title: "Starter",
    price: 300,
    id:"",
    features: [
      "Browse the marketplace to see non-premium and starter businesses.",
      "Access to All knowledge Hub resources.",
      "Reach out to founders to discuss acquisition.",
      "Unlimited access to vetted startups of up to $250,000 TTM revenue.",
      "Unlimited filtration on marketplace to access the businesses your looking for.",
      "Access to buyer dashboard to manage deals.",
      "Best fit for those starting their acquisition/investment journeys.",
    ],
    paymentLink: "https://buy.stripe.com/8wM7tx56H4qM4bmcMN",
  },
  {
    title: "Premium",
    price: 400,
    id:"",
    features: [
      "Browse the marketplace fully.",
      "Access to buyer dashboard to manage deals.",
      "Access to pre-vetted top 10 list by Exits.",
      "Unlimited access to all startups (of any sizes) including those managed by Exits in offline advisory.",
      "Access to 2 free consultation sessions with Exits Investment team with possible recommendations.",
      "Unlimited filtration on marketplace to access the businesses your looking for.",
      "Priority customer support.",
      "Best fit for those starting their acquisition /investment journeys.",
    ],
    paymentLink: "https://buy.stripe.com/bIYeVZar1aPafU4fYY",
  },
];
export default function PaymentPage() {
  const { push } = useHistory();
  const {
    userState: { token },
  } = useContext(UserStore);
  const [remainningAdvisories, setRemainningAdvisories] = useState(0);

  async function getRemainningAdvisories() {
    try {
      const res = await get("/advisory/count", token);
      setRemainningAdvisories(res.data.remaining);
    } catch (e) {
      console.log(e);
    }
  }
  useEffect(() => {
    !!token && getRemainningAdvisories();
  }, [token]);
  return (
    <Flex column>
      <Wrapper>
        {plans.map((plan) => (
          <PricingCardo {...plan} />
        ))}
      </Wrapper>
      <div style={{ margin: "2rem auto" }}>
        <Button
          variant="contained"
          disabled={!remainningAdvisories}
          onClick={() => push("/advisory")}
        >
          Request Advisory {remainningAdvisories}/3
        </Button>
      </div>
    </Flex>
  );
}
