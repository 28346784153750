import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: any) => ({
  header: {
    borderBottom: `1px solid ${theme.palette.border.grayD3}`,
    maxHeight: "80px",
    // transition: 'all 0.5s ease-in-out',
    "&.MuiAppBar-root": {
      backgroundColor: theme.palette.colors.header,
      "&.inverse-Header": {
        backgroundColor: theme.palette.colors.header,
        "& $headerDesktop": {
          alignItems: "center",
        },
      },
    },
    // '&.shrink': {
    //   boxShadow: '0 0 5px hsl(0, 0%, 85%)',
    //   transition: 'all 0.5s ease-in-out',
    //   padding: theme.spacing(0)
    // }
  },
  headerToolbar: {
    // minHeight: theme.spacing(8.75),
    justifyContent: "center",
    padding: "0 80px ",
    [theme.breakpoints.down("sm")]: {
      padding: "0 50px ",
    },
  },
  headerDesktop: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexGrow: 1,
  },
  headerGridContainer: {
    maxWidth: "1440px",
    [theme.breakpoints.down("sm")]: {
      flexWrap: "nowrap",
    },
  },
  iconWrapper: {
    color: theme.palette.primary.main,
    margin: theme.spacing(1),
    cursor: "pointer",
    "&:hover, &:focus": {
      color: theme.palette.primary.main,
      outline: "none",
    },
  },
  languageHeaderWrapper: {
    width: "220px",
    padding: "15px 10px",
    flexDirection: "column",
    display: "flex",
  },
  languageRadioHeaderWrapper: {
    justifyContent: "space-between",
    display: "flex",
  },
  languageText: {
    color: theme.palette.text.main,
    font: "normal normal 600 14px20px Nunito Sans",
    margin: "0px",
    textAlign: "start",
  },
  languageRadioWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
  },

  mobileMenu: {
    padding: "0 25px",
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  menuContainer: {
    width: "217px",
    height: "300px",
    display: "flex",
    flexDirection: "column",
    borderRadius: "15px",
  },
  oneMenuContainer: {
    width: "100%",
    height: "32px",
    backgroundColor: "#F8F9F9",
    padding: "7px 15px",
    cursor: "pointer",
    marginBottom: "10px",
  },
  menuTextsContainer: {
    width: "50%",
    overflow: "none",
    flexWrap:'wrap',
    flexDirection: "column",
    alignContent: "center",
    justifyContent: "center",
    display: "flex",
  },
  menuTitleText: {
    color: "#49545B",
    font: "Roboto",
    fontWeight: "normal",
    margin: "0px",
    textAlign: "start",
  },
  userNameMenuText: {
    color: "#000",
    font: "Roboto",
    fontWeight: "bold",
    fontSize: "12px",
    width: "10px",
    margin: "0px",
    textAlign: "start",
  },
  userTypeText: {
    color: "#1E70FC",
    font: "Roboto",
    fontWeight: "bold",
    fontSize: "10px",
    margin: "0px",
    textAlign: "start",
  },
  contactMenuContainer: {
    width: "90%",
    maxWidth: "75px",
    padding: "20px 15px",
    flexDirection: "row",
    alignContent: "center",
    display: "flex",
  },
}));
