import { makeStyles } from "@material-ui/core/styles";

// const menuHeightLg = 53
export const useStyles = makeStyles((theme) => ({
  mainNavigationWrapper: {
    display: "flex",
    flex: "1 1 auto",
    justifyContent: "center",
  },
  mainNavigation: {
    [theme.breakpoints.up("lg")]: {
      display: "flex",
      position: "relative",
      paddingRight: theme.typography.pxToRem(43),
      paddingLeft: theme.typography.pxToRem(43),
    },
    [theme.breakpoints.down("md")]: {
      display: "flex",
      position: "relative",
      paddingRight: theme.typography.pxToRem(23),
      paddingLeft: theme.typography.pxToRem(23),
    },
    // [theme.breakpoints.up("sm")]: {
    //   display: "flex",
    //   position: "relative",
    //   paddingRight: theme.typography.pxToRem(23),
    //   paddingLeft: theme.typography.pxToRem(23),
    // },
    "& ul": {
      listStyle: "none",
      overflow: "hidden",
      margin: theme.spacing(0),
      padding: theme.spacing(0),
      [theme.breakpoints.up("sm")]: {
        height: theme.typography.pxToRem(43),
        display: "flex",
        // backgroundColor: theme.palette.primary.light
      },
    },
    "& a": {
      color: theme.palette.text.primary,
      padding: `${theme.typography.pxToRem(12)} ${theme.typography.pxToRem(
        20
      )}`,
      display: "inline-block",
      fontWeight: theme.typography.fontWeightLight,
      textTransform: "capitalize",
      textDecoration: "none",
      lineHeight: 1.36,
      fontSize: theme.typography.pxToRem(20),
      [theme.breakpoints.up("lg")]: {
        height: theme.typography.pxToRem(43),
        whiteSpace: "nowrap",
      },
      "&.active": {
        // backgroundColor: alpha(theme.palette.common.black, 0.2)
      },
      "&:hover, &:focus": {
        color: theme.palette.primary.main,
      },
      // '& span': {
      //   color: theme.palette.secondary.main,
      //   fontWeight: theme.typography.fontWeightBold,
      //   paddingTop: theme.spacing(0.25),
      //   display: 'inline-block',
      //   fontSize: theme.typography.pxToRem(24),
      //   lineHeight: theme.typography.pxToRem(14),
      //   [theme.breakpoints.up('lg')]: {
      //     verticalAlign: 'top'
      //   }
      // }
    },
    "& .hidden-item": {
      opacity: 0,
    },
  },
}));
