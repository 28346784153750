import { useCallback, useContext } from "react";
import { useHistory } from "react-router-dom";
import userContext, { initialState } from "../../store/userContext/context";
import { clearStorage, removeFromStorage } from "../../utils/localStorage";
import useApi from "../useApiFetching";
const useLogoutRequest = () => {
  const { postRequest } = useApi();
  const { setUserContext } = useContext(userContext);
  const { push } = useHistory();

  const handleLogOut = useCallback(
    async (isHeader?: boolean) => {
      const res = await postRequest("/auth/logout");
      if (res) {
        removeFromStorage("@exitsUser");
        removeFromStorage("@exitsUserToken");
        clearStorage();
        setUserContext(initialState);
        if (!isHeader) push("/signin");
      }
    },
    [push, setUserContext, postRequest]
  );

  return {
    handleLogOut,
  };
};

export default useLogoutRequest;
