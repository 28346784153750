import { createContext } from "react";

import { State, StoreApi } from "./index.interface";

export const initialState: State = {
  token: "",
  disabled: false,
  isVerified: true,
  deviceType: "",
  _id: "",
  email: "",
  userType: "",
  verificationCode: null,
  birthday: "",
  firstName: "",
  gender: "",
  lastName: "",
  linkedinAccount: "",
  password: "",
  phoneNumber: "",
  testcust: "",
  selectedPlan: "basic",
};

export default createContext<StoreApi>({
  userState: initialState,
  // @ts-ignore
  setUserContext: (state: State) => state,
});
