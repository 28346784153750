//@ts-nocheck
import { useContext } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { GeneralConsumer } from "../../store/GeneralContext";
import { ThemeConsumer } from "../../store/ThemeProvider";
import { NamedRoutes } from "../../routes/index";
import { useStyles } from "./Styles";
import userStore from "../../store/userContext/context";

// import { fontResizerClass } from '../../utils/helpers'
// import { useLocation } from "react-router-dom";

const MainNavigation = ({ pageSettings }: any) => {
  const { userState } = useContext(userStore);

  let defaultMenu =
    userState.userType === "Seller"
      ? [
          {
            key: "My Listing",
            label: "My Seller Dashboard",
            link: NamedRoutes.MyListing,
          },
          {
            key: "Marketplace",
            label: "Marketplace",
            link: NamedRoutes.marketPlace,
          },
        ]
      : [
          {
            key: "My Acquisition",
            label: "My Buyer Dashboard",
            link: NamedRoutes.myAcquisition,
          },
          {
            key: "Marketplace",
            label: "Marketplace",
            link: NamedRoutes.marketPlace,
          },
        ];

  const classes = useStyles();
  const location = useLocation();
  return (
    <GeneralConsumer>
      {({ toggleMobileMenuDrawer, selectedTab }: any) => (
        <ThemeConsumer>
          {({ fontSize }: any) => (
            <div className={classes.mainNavigationWrapper}>
              <nav className={classes.mainNavigation}>
                <ul>
                  {defaultMenu.map((item, index) => (
                    <li
                      style={{
                        color:
                          location.pathname === item.link
                            ? "#0040AC"
                            : "#565656",
                      }}
                      key={item.key || index}
                    >
                      <NavLink
                        exact
                        activeClassName="active"
                        to={item.link}
                        onClick={() => toggleMobileMenuDrawer(false, item.link)}
                      >
                        <span
                          style={{
                            color:
                              location.pathname === item.link
                                ? "#0040AC"
                                : "#565656",
                            fontWeight:
                              location.pathname === item.link
                                ? "normal"
                                : "normal",
                            // fontWeight: selectedTab === item.link? "bold" : 'normal'
                          }}
                        >
                          {item.label}
                        </span>
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </nav>
            </div>
          )}
        </ThemeConsumer>
      )}
    </GeneralConsumer>
  );
};

export default MainNavigation;
