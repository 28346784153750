//@ts-nocheck
import React, { createContext } from "react";
// import {create} from 'jss'
// import rtl from 'jss-rtl'
import { BrowserRouter as Router } from "react-router-dom";
import {
  createGenerateClassName,
  createTheme,
  alpha,
} from "@material-ui/core/styles";
import {
  StylesProvider,
  ThemeProvider as MuiThemeProvider,
} from "@material-ui/styles";
// import {LocalStorageService} from '../services/LocalStorageService'
// import Configuration from './Services/Api/z'

// Configure JSS
// const jss = create({plugins: [...jssPreset().plugins, rtl()]})
const generateClassName = createGenerateClassName();
// let {DefaultFont, DefaultDirection} = Configuration

const ThemeContext = createContext({});
const ThemeProvider = ({ children }: any) => {
  // default font
  // const [font, setFont] = React.useState()
  // const [storageDirection, setStorageDirection] = React.useState(DefaultDirection)
  // const [fontSize, setFontSize] = React.useState(14)
  const fontSizes = [18, 14, 12];
  const [fontSize] = React.useState(14);

  // const changeFontSize = (event:any) => {
  //   setFontSize(parseInt(event))
  // }

  let theme = createTheme({
    direction: "ltr",
    palette: {
      primary: {
        light: "#1E70FC",
        main: "#0040AC",
        dark: "#903d27",
        contrastText: "#fff",
      },
      secondary: {
        light: "#56565699",
        main: "#565656",
        dark: "#565656",
        contrastText: "#fff",
      },
      border: {
        grayD3: "#D3D6D8",
        greyA7: "#A7ADB0",
      },
      error: {
        light: "#e57373",
        main: "#f44336",
        dark: "#d32f2f",
        contrastText: "#fff",
      },
      success: {
        light: "#81c784",
        main: "#4caf50",
        dark: "#388e3c",
        contrastText: alpha("#000", 0.87),
      },
      info: {
        light: "#64b5f6",
        main: "#2196f3",
        dark: "#1E70FC",
        contrastText: "#fff",
      },
      warning: {
        light: "#ffb74d",
        main: "#ff9800",
        dark: "#f57c00",
        contrastText: alpha("#000", 0.87),
      },
      text: {
        primary: "#000000",
        secondary: "#49545B",
        third: "#E8E9EB",
        gray7c: "#6D767C",
        h4: "#49545B",
        hint: "#A7ADB0",
      },
      colors: {
        offWhite: "#F1F1F1",
        body: "#FFFFFF",
        // table: '#E8E8E8',
        footer: "#6d6d6d",
        border: "#74727f",
        border2: "#E7E7E7",
        header: "#FFFFFF",
        backgroundLight: "#F4F8FB",
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
    typography: {
      fontFamily: "Roboto",
      htmlFontSize: 16,
      fontSize: fontSize,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightBold: 700,
    },
  });

  return (
    <StylesProvider generateClassName={generateClassName}>
      <ThemeContext.Provider
        value={{
          // changeFontSize,
          fontSizes,
          fontSize,
        }}
      >
        <MuiThemeProvider theme={theme}>
          <Router>{children}</Router>
        </MuiThemeProvider>
      </ThemeContext.Provider>
    </StylesProvider>
  );
};

const ThemeConsumer = ThemeContext.Consumer;

export { ThemeProvider, ThemeConsumer };
