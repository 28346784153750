import React, { FC, useCallback } from "react";
import { Link } from "react-router-dom";
// import { FormattedMessage } from 'react-intl'
import logo from "../../assets/images/Logo.svg";
import Button from "@mui/material/Button";
import { useStyles } from "./Styles";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import useLogoutRequest from "../../services/GeneralHooks/logoutHook";

// import CloseIcon from '@mui/icons-material/Close';

interface Props {
  location: any;
}
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const Logo: FC<Props> = ({ location }) => {
  const classes = useStyles();
  const locationClass = location !== "Landing" ? "inner" : "";
  const [open, setOpen] = React.useState(false);

  const { handleLogOut } = useLogoutRequest();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleNavigate = useCallback(() => {
    handleLogOut(true).then(() => {
      handleClose();
      window.location.replace("https://exits-landing-staging.anspire.co");
    });
  }, [handleLogOut]);

  const handleDialog = React.useMemo(() => {
    return (
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Confirmation
        </DialogTitle>

        <DialogContent dividers>
          <Typography gutterBottom>
            Are you sure you want to Sign out and exit?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleNavigate}>Leave</Button>
          <Button onClick={handleClose}>Stay</Button>
        </DialogActions>
      </BootstrapDialog>
    );
  }, [handleNavigate, open]);
  return (
    <div className={`${classes.logo} ${locationClass}`}>
      {handleDialog}
      {/* <Link to="/"> */}
      <div
        className={classes.LogoImage}
        onClick={() =>
          // window.location.replace("https://exits-landing-staging.anspire.co")
          handleClickOpen()
        }
      >
        <img height={80} width={150} src={logo} alt="Exits Logo" />
      </div>
      {/* </Link> */}
      {/* {location !== 'home' && <Link to="/" className={classes.innerLogo}>
        <FormattedMessage id='logo.innerLogo'/>
      </Link>} */}
    </div>
  );
};

export default Logo;
