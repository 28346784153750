import { makeStyles } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme: any) => ({
  footer: {
    padding: theme.spacing(1.5),
    height: "50px",
    flexDirection: "row",
    background: "#f8f8f8",
    display: "flex",
    justifyContent: "center",
  },
}));
