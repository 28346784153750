import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  gap: 2rem;
  margin: 0 1rem;
  @media (max-width: 920px) {
    flex-direction: column;
  }
  @media (max-width: 1199.95px) {
    margin: 40px 1rem;
  }
`;
