/* eslint-disable no-restricted-globals */
import React from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
// import ScrollToTop from '../../utils/ScrollToTop'

// import ScrollTop from '../ScrollTop/ScrollTop'
import { useStyles } from "./Styles";
import Header from "./Header";
import Footer from "./Footer";
// import FontResizer from '../FontResizer'

class Layout extends React.PureComponent {
  handleRouteBasedClassName = () => {
    //@ts-ignore
    const { location } = this.props;
    //@ts-ignore
    return location.pathname === "/" ? "home" : "inverse-Header";
  };

  render() {
    //@ts-ignore
    const { classes } = this.props;
    //@ts-ignore
    // const { location } = this.props;
    return (
      <div
        className={`${classes.pageWrapper} ${this.handleRouteBasedClassName()}`}
      >
        <CssBaseline />
        {/* <ScrollToTop/> */}
        {/* {location.pathname !== "/signin" && ( */}
        <Header location={this.handleRouteBasedClassName()} />
        {/* )} */}
        <main
          className={
            location.pathname === "/signin" || location.pathname === "/signup"
              ? classes.content2
              : classes.content
          }
        >
          {this.props.children}
        </main>
        {/* {location.pathname !== "/Services" &&location.pathname !== "/Checkout" && */}
        <Footer />
        {/* <FontResizer/> */}
        {/* <ScrollTop scrollStepInPx="50" delayInMs="0"/> */}
      </div>
    );
  }
}

//@ts-ignore
export default withRouter(withStyles(useStyles)(Layout));
