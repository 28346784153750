// @ts-nocheck
import React, { FC, useContext, useEffect } from "react";
import { Route } from "react-router-dom";
import useStore from "../../store/userContext/context";
import RouteWrapperInterface from "./index.interface";
import { useHistory } from "react-router-dom";
import { getFromStorage } from "../../utils/localStorage";
const RouteWrapper: FC<RouteWrapperInterface> = ({
  component: Component,
  layout: Layout,
  ...rest
}): JSX.Element => {
  // console.log(rest.path);
  const {
    push,
    location: { pathname },
  } = useHistory();
  const { setUserContext } = useContext(useStore);

  const handleUserData = () => {
    const User = getFromStorage("@exitsUser");
    if (User && !!Object.keys(User).length) {
      // @ts-ignore
      setUserContext(User);
      // handleSidebarResponsive();
      console.log(User, "user");
    } else {
      // if ('Notification' in window) firebase.messaging().deleteToken();
      if (pathname !== "/signin" && pathname !== "/signup" && pathname !=="/linkedin")
        return push("/signin");
    }
  };
  useEffect(() => {
    handleUserData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Route
        {...rest}
        render={(props) => {
          return (
            <Layout {...props}>
              <Component {...props} />
            </Layout>
          );
        }}
      />
    </>
  );
};

export default React.memo(RouteWrapper);
