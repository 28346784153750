import { TextField } from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Flex from "../../components/flex";
import { get, post } from "../../utils/request";
import { useContext, useEffect, useState } from "react";
import UserStore from "../../store/userContext/context";
import { toast } from "react-toastify";
import { Button } from "../../components/PricingModal/Card/styled";
import * as yup from "yup";

export default function Advisorypage() {
  const {
    userState: { token },
  } = useContext(UserStore);
  const [remainningAdvisories, setRemainningAdvisories] = useState(3);
  async function requestAdvisory(values: any) {
    try {
      await post("/advisory", values, token);
      toast.success("Advisory sent successfully");
    } catch (e: any) {
      toast.error(e?.response?.data?.message || "Something went wrong");
      console.log(e);
    }
  }
  async function getRemainningAdvisories() {
    try {
      const res = await get("/advisory/count", token);
      setRemainningAdvisories(res.data.remaining);
    } catch (e) {
      console.log(e);
    }
  }
  useEffect(() => {
    !!token && getRemainningAdvisories();
  }, [token]);
  const validationSchema = yup.object().shape({
    email: yup.string().email("Invalid email").required("Required"),
    name: yup.string().required("Required"),
    title: yup.string().required("Required"),
    company: yup.string().required("Required"),
    linkUrl: yup.string().url("Invalid URL").required("Required"),
    msg: yup.string().required("Required"),
  });
  return (
    <div
      style={{
        width: "30rem",
        backgroundColor: "white",
        padding: "2rem",
        borderRadius: "1rem",
        margin: "3rem auto",
        height: "fit-content",
      }}
    >
      <h1 style={{ color: "#003fad" }}>Contact Us</h1>
      <Formik
        initialValues={{
          email: "",
          name: "",
          title: "",
          company: "",
          linkUrl: "",
          msg: "",
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
          // Handle form submission here
          requestAdvisory(values);
          resetForm(); // Reset the form after submission
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form>
            <Flex gap="1rem" column={true}>
              <TextField
                name="name"
                label={"Name"}
                onBlur={handleBlur}
                value={values.name}
                onChange={handleChange}
              />
              {errors.name && touched.name && errors.name}

              <TextField
                name="email"
                label={"Email Address"}
                onBlur={handleBlur}
                value={values.email}
                onChange={handleChange}
              />
              {errors.email && touched.email && errors.email}

              <TextField
                name="title"
                label={"Title"}
                onBlur={handleBlur}
                value={values.title}
                onChange={handleChange}
              />
              {errors.title && touched.title && errors.title}

              <TextField
                name="company"
                label={"Company"}
                onBlur={handleBlur}
                value={values.company}
                onChange={handleChange}
              />
              {errors.company && touched.company && errors.company}

              <TextField
                name="linkUrl"
                label={"Linkedin Url"}
                onBlur={handleBlur}
                value={values.linkUrl}
                onChange={handleChange}
              />
              {errors.linkUrl && touched.linkUrl && errors.linkUrl}
              <TextField
                name="msg"
                label={"message"}
                onBlur={handleBlur}
                value={values.msg}
                onChange={handleChange}
              />
              {errors.msg && touched.msg && errors.msg}

              <Button
                disabled={isSubmitting || !remainningAdvisories}
                type="submit"
              >
                Submit
              </Button>
              {!remainningAdvisories && (
                <p>you have no advisory left for this month</p>
              )}
            </Flex>
          </Form>
        )}
      </Formik>
    </div>
  );
}
