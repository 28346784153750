import { useContext } from "react";
// import message from 'antd';
import { get, post, patch, put, deleteReq } from "../utils/request";
// import { getNotifications, readAllNotifications } from './api';
// import userContext from '../store/context';
import userContext from "../store/userContext/context";
import { toast } from "react-toastify";

const useApi = () => {
  const {
    userState: {
      token,
      //   refreshToken,
      //   lang,
      //   selectedRegion,
    },
  } = useContext(userContext);

  // let token =
  //   contextToken !== "" ? contextToken : userData ? userData?.token : "";

  // const getRequest = async (url: string, showMessage?: boolean) => {
  //   let langSend = lang === null ? "en" : lang === "en" ? "en" : "ar";
  //   try {
  //     const response = await get(url, token, langSend);
  //     return response.data;
  //   } catch (error: any) {
  //     if (error.message === "Request failed with status code 401") {
  //       try {
  //         const refreshResponse = await post(
  //           "/api/tokens/refresh",
  //           {
  //             token,
  //             refreshToken,
  //           },
  //           token,
  //           langSend
  //         );

  //         if (refreshResponse.status === Configuration.statusCode.success) {
  //           setUserContext((old) => {
  //             return {
  //               ...old,
  //               token: refreshResponse.data.token,
  //               refreshToken: refreshResponse.data.refreshToken,
  //             };
  //           });
  //           const fetchSameApi = await get(url, token, langSend);
  //           if (fetchSameApi.status === 200) return fetchSameApi.data;
  //         }
  //       } catch (errors) {
  //         push("/signin");
  //       }
  //     }
  //     // console.log(error.status);
  //   }
  // };

  const postRequest = async (
    url: string,
    body?: any,
    showMessage?: boolean
  ) => {
    // let langSend = lang === null ? "en" : lang === "en" ? "en" : "ar";

    return await post(url, body, token)
      .then((res) => {
        if (showMessage && url !== "auth/login") {
          toast.success(res?.data?.message, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        return res.data;
      })
      .catch(async (err) => {
        // if (
        //   err.response?.data?.status?.code ===
        //   Configuration.statusCode.refreshToken
        // ) {
        //   try {
        //     const refreshResponse = await post(
        //       Configuration.endPoints.refreshToken,
        //       {
        //         refresh_token: refreshToken,
        //       },
        //       token,
        //       langSend
        //     );

        //     // if (refreshResponse.status === Configuration.statusCode.success) {
        //     //   setUserContext((old) => {
        //     //     return {
        //     //       ...old,
        //     //       token: refreshResponse.data.access_token,
        //     //       refreshToken: refreshResponse.data.refresh_token,
        //     //     };
        //     //   });
        //     //   const fetchSameApi = await post(url, body, token, langSend);
        //     //   if (fetchSameApi.status === Configuration.statusCode.success)
        //     //     return fetchSameApi.data;
        //     // }
        //   } catch (errors) {
        //     console.log(errors);
        //     push("/signin");
        //   }
        //   // let refreshResponse = await post(
        //   //   '/api/tokens/refresh',
        //   //   {
        //   //     token,
        //   //     refreshToken
        //   //   },
        //   //   token,
        //   //   langSend
        //   // );
        //   // if (refreshResponse.status === 200) {
        //   //   setUserContext((old) => {
        //   //     return {
        //   //       ...old,
        //   //       token: refreshResponse.data.token,
        //   //       refreshToken: refreshResponse.data.refreshToken
        //   //     };
        //   //   });
        //   // }
        // } else
        if (showMessage) {
          toast.error(err.response?.data?.message, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            // theme: "light",
          });
        }
        return false;
      });
  };

  const putRequest = async (
    url: string,
    body?: any,
    showMessage?: boolean,
    tokenReceived?: string
  ) => {
    // let langSend = lang === null ? "en" : lang === "en" ? "en" : "ar";
    let tokenHere = tokenReceived ? tokenReceived : token;
    console.log(tokenHere, "token in put");
    return await put(url, body, tokenHere)
      .then((res) => {
        if (showMessage) {
          toast.success(res?.data?.message, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        return res.data;
      })
      .catch(async (err) => {
        // if (
        //   err.response?.data?.status?.code ===
        //   Configuration.statusCode.refreshToken
        // ) {
        //   try {
        //     const refreshResponse = await post(
        //       Configuration.endPoints.refreshToken,
        //       {
        //         refresh_token: refreshToken,
        //       },
        //       token,
        //       langSend
        //     );

        //     // if (refreshResponse.status === Configuration.statusCode.success) {
        //     //   setUserContext((old) => {
        //     //     return {
        //     //       ...old,
        //     //       token: refreshResponse.data.access_token,
        //     //       refreshToken: refreshResponse.data.refresh_token,
        //     //     };
        //     //   });
        //     //   const fetchSameApi = await post(url, body, token, langSend);
        //     //   if (fetchSameApi.status === Configuration.statusCode.success)
        //     //     return fetchSameApi.data;
        //     // }
        //   } catch (errors) {
        //     console.log(errors);
        //     push("/signin");
        //   }
        //   // let refreshResponse = await post(
        //   //   '/api/tokens/refresh',
        //   //   {
        //   //     token,
        //   //     refreshToken
        //   //   },
        //   //   token,
        //   //   langSend
        //   // );
        //   // if (refreshResponse.status === 200) {
        //   //   setUserContext((old) => {
        //   //     return {
        //   //       ...old,
        //   //       token: refreshResponse.data.token,
        //   //       refreshToken: refreshResponse.data.refreshToken
        //   //     };
        //   //   });
        //   // }
        // } else
        if (showMessage) {
          toast.error(err.response?.data?.message, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            // theme: "light",
          });
        }
        return false;
      });
  };

  const patchRequest = async (
    url: string,
    body?: any,
    showMessage?: boolean
  ) => {
    // let langSend = lang === null ? "en" : lang === "en" ? "en" : "ar";
    return await patch(url, body, token)
      .then((res) => {
        toast.success(res?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return res.data;
      })
      .catch(async (err) => {
        // if (
        //   err.response?.data?.status?.code ===
        //   Configuration.statusCode.refreshToken
        // ) {
        //   try {
        //     const refreshResponse = await post(
        //       Configuration.endPoints.refreshToken,
        //       {
        //         refresh_token: refreshToken,
        //       },
        //       token,
        //       langSend
        //     );

        //     if (refreshResponse.status === Configuration.statusCode.success) {
        //       setUserContext((old) => {
        //         return {
        //           ...old,
        //           token: refreshResponse.data.access_token,
        //           refreshToken: refreshResponse.data.refresh_token,
        //         };
        //       });
        //       const fetchSameApi = await post(url, body, token, langSend);
        //       if (fetchSameApi.status === Configuration.statusCode.success)
        //         return fetchSameApi.data;
        //     }
        //   } catch (errors) {
        //     push("/signin");
        //   }
        //   let refreshResponse = await post(
        //     "/api/tokens/refresh",
        //     {
        //       token,
        //       refreshToken,
        //     },
        //     token,
        //     langSend
        //   );
        //   if (refreshResponse.status === 200) {
        //     setUserContext((old) => {
        //       return {
        //         ...old,
        //         token: refreshResponse.data.token,
        //         refreshToken: refreshResponse.data.refreshToken,
        //       };
        //     });
        //   }
        // } else

        if (showMessage) {
          toast.error(err.response?.data?.message, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            // theme: "light",
          });
        }
        return false;
      });
  };

  const deleteRequest = async (url: string, showMessage?: boolean) => {
    // let langSend = lang === null ? "en" : lang === "en" ? "en" : "ar";
    return await deleteReq(url, token)
      .then((res) => {
        toast.success(res?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return res.data;
      })
      .catch(async (err) => {
        // if (
        //   err.response?.data?.status?.code ===
        //   Configuration.statusCode.refreshToken
        // ) {
        //   try {
        //     const refreshResponse = await post(
        //       Configuration.endPoints.refreshToken,
        //       {
        //         refresh_token: refreshToken,
        //       },
        //       token,
        //       langSend
        //     );

        //     if (refreshResponse.status === Configuration.statusCode.success) {
        //       setUserContext((old) => {
        //         return {
        //           ...old,
        //           token: refreshResponse.data.access_token,
        //           refreshToken: refreshResponse.data.refresh_token,
        //         };
        //       });
        //       const fetchSameApi = await post(url, body, token, langSend);
        //       if (fetchSameApi.status === Configuration.statusCode.success)
        //         return fetchSameApi.data;
        //     }
        //   } catch (errors) {
        //     push("/signin");
        //   }
        //   let refreshResponse = await post(
        //     "/api/tokens/refresh",
        //     {
        //       token,
        //       refreshToken,
        //     },
        //     token,
        //     langSend
        //   );
        //   if (refreshResponse.status === 200) {
        //     setUserContext((old) => {
        //       return {
        //         ...old,
        //         token: refreshResponse.data.token,
        //         refreshToken: refreshResponse.data.refreshToken,
        //       };
        //     });
        //   }
        // } else

        if (showMessage) {
          toast.error(err.response?.data?.message, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            // theme: "light",
          });
        }
        return false;
      });
  };

  const getRequest = async (
    url: string,
    showMessage?: boolean,
    tokenCache?: string
  ) => {
    // let langSend = lang === null ? "en" : lang === "en" ? "en" : "ar";
    // let selectedRegionSend = selectedRegion ? selectedRegion.id : 1;
    console.log(token, "token in get");

    let tokenHere = tokenCache ? tokenCache : token;
    return await get(url, tokenHere)
      .then((res) => {
        return res.data;
      })
      .catch(async (err) => {
        // if (
        //   err.response.data.status.code ===
        //   Configuration.statusCode.refreshToken
        // ) {
        //   try {
        //     const refreshResponse = await post(
        //       Configuration.endPoints.refreshToken,
        //       {
        //         refresh_token: refreshToken,
        //       },
        //       token,
        //       langSend
        //     );

        //     if (refreshResponse.status === Configuration.statusCode.success) {
        //       setUserContext((old) => {
        //         return {
        //           ...old,
        //           token: refreshResponse.data.access_token,
        //           refreshToken: refreshResponse.data.refresh_token,
        //         };
        //       });
        //       const fetchSameApi = await get(
        //         url,
        //         tokenHere,
        //         langSend,
        //         selectedRegionSend
        //       );
        //       if (fetchSameApi.status === Configuration.statusCode.success)
        //         return fetchSameApi.data;
        //     }
        //   } catch (errors) {
        //     push("/signin");
        //   }
        //   let refreshResponse = await post(
        //     "/api/tokens/refresh",
        //     {
        //       token,
        //       refreshToken,
        //     },
        //     token,
        //     langSend
        //   );
        //   if (refreshResponse.status === 200) {
        //     setUserContext((old) => {
        //       return {
        //         ...old,
        //         token: refreshResponse.data.token,
        //         refreshToken: refreshResponse.data.refreshToken,
        //       };
        //     });
        //   }
        // } else
        if (showMessage) {
          toast.error(err.response?.data?.message, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            // theme: "light",
          });
        }
        return false;
      });
  };

  const getCompanyByIdRequest = async (
    id: string,
    token: string,
    status?: string,
    seller?: string,
    businessName?: string,
    businessType?: string,
    page?: number
  ) => {
    try {
      let params = `/company?_id=${id}`;

      // Append seller if provided
      if (seller) {
        params += `&seller=${seller}`;
      }
      if (status) {
        params += `&status=${status}`;
      }
      // Append page if provided
      if (page) {
        params += `&page=${page}`;
      }

      // Append businessName if provided and not empty
      if (businessName && businessName !== "") {
        params += `&personalInformation.businessName=${businessName}`;
      }

      // Append businessType if provided and not empty
      if (businessType && businessType !== "") {
        params += `&personalInformation.businessType=${businessType}`;
      }
      const res = await get(params, token);
      return res.data;
    } catch (error: any) {
      toast.error(error.response?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        // theme: "light",
      });
    }
  };

  return {
    getRequest,
    postRequest,
    patchRequest,
    putRequest,
    deleteRequest,
    getCompanyByIdRequest,
  };
};

export default useApi;
