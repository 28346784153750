import {
  Wrapper,
  Header,
  Body,
  BulletPoint,
  Footer,
  PriceWrapper,
} from "./styled";
import { Button } from "@material-ui/core";
import Check from "../../../assets/images/Check.svg";
import { useContext } from "react";
import UserStore from "../../../store/userContext/context";

export default function PricingCardo(props: any) {
  const { userState } = useContext(UserStore);
  const isSamePlan =
    userState.selectedPlan?.toLowerCase() === props.title.toLowerCase(); /// selectedPlan === PRICE_Sdjnfjsdnfkjsdnfknd subscribed. 
  function navigateToPayment(link: string) {
    window.open(link, "_blank");
  }
  return (
    <Wrapper>
      <Header>{props.title}</Header>
      <PriceWrapper>
        {props.price ? (
          <>
            <p>$</p>
            <h4>{props.price}</h4>
            <span>/Yearly</span>
          </>
        ) : (
          <h4>Free</h4>
        )}
      </PriceWrapper>
      <Body>
        {props.features.map((feature: any) => (
          <BulletPoint>
            <img src={Check} />
            <p>{feature}</p>
          </BulletPoint>
        ))}
      </Body>
      <Footer>
        {!!props.paymentLink && (
          <Button
            variant="contained"
            fullWidth
            disabled={isSamePlan}
            onClick={() => navigateToPayment(props.paymentLink)}
          >
            {isSamePlan ? "Subscribed" : "Subscribe"}
          </Button>
        )}
      </Footer>
    </Wrapper>
  );
}
