import firebase from "firebase/app";
import "firebase/messaging";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBD8GsJAv_-jNFZSwgqL_NViTH1h3kIid8",
  authDomain: "exit-767df.firebaseapp.com",
  databaseURL: "https://exit-767df-default-rtdb.firebaseio.com",
  projectId: "exit-767df",
  storageBucket: "exit-767df.appspot.com",
  messagingSenderId: "771670875524",
  appId: "1:771670875524:web:a02509b6f57f52c4e091d1",
  measurementId: "G-BG3GZV25QY",
};

firebase.initializeApp(firebaseConfig);

const messaging = firebase.messaging();

const { REACT_APP_VAPID_KEY } = process.env;
const publicKey = "BCCyY0kGfrx8BCIAgQvkV_tS64EJxs6Xu8nepTVkxry_OK8btBhvjKf1aUPoGm4_j-q0eSWtiimY8Gd2NCc0nFo";

export const getToken = async (setTokenFound: any) => {
  let currentToken = "";

  try {
    currentToken = await messaging.getToken({ vapidKey: publicKey });
    if (currentToken) {
      setTokenFound(true);
    } else {
      setTokenFound(false);
    }
  } catch (error) {
    console.log("An error occurred while retrieving token. ", error);
  }

  return currentToken;
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });
