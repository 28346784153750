/**
 * General Styles For The Layout
 * @type {function(*): {}}
 */
//@ts-nocheck

import { EnglishFont } from "../../utils/Fonts";
import { alpha } from "@material-ui/core";

export const useStyles = (theme: any) => ({
  "@global": {
    html: { minHeight: "100%" },
    body: {
      height: "100%",
      backgroundColor: theme.palette.colors.body,
    },
    "#root": {
      height: "100%",
      whiteSpace: "pre-line",
    },
    a: {
      transition: "all 0.3s ease-in-out",
      color: theme.palette.primary.main,
      "&:hover, &:focus": {
        color: theme.palette.secondary.main,
      },
    },
    button: {
      fontFamily: theme.direction === "ltr" ? EnglishFont : English,
      cursor: "pointer",
      transition: "all 0.3s ease-in-out",
      outline: "none !important",
    },
    img: {
      maxWidth: "100%",
      height: "auto",
      display: "inline-block",
    },
    ".require": {
      color: theme.palette.error.main,
    },
    h1: {
      fontFamily: theme.direction === "ltr" ? EnglishFont : EnglishFont,
      fontWeight: "800",
      color: theme.palette.text.primary,
      fontSize: "32px",
      margin: "0 0 24px 0",
      textAlign: "start",
    },
    h2: {
      fontFamily: theme.direction === "ltr" ? EnglishFont : EnglishFont,
      fontWeight: "500",
      fontSize: "16px",
      color: theme.palette.text.secondary,
      margin: "0 0 0px 0",
      textAlign: "start",
    },
    h3: {
      fontFamily: theme.direction === "ltr" ? EnglishFont : EnglishFont,
      fontWeight: "500",
      fontSize: "12px",
      color: theme.palette.text.secondary,
      margin: "0 0 0px 0",
      textAlign: "end",
    },
    h4: {
      fontFamily: theme.direction === "ltr" ? EnglishFont : EnglishFont,
      fontWeight: "500",
      fontSize: "14px",
      color: theme.palette.text.secondary,
      margin: "0 0 0px 5px",
    },
    h5: {
      fontFamily: theme.direction === "ltr" ? EnglishFont : EnglishFont,
      fontWeight: "500",
      fontSize: "12px",
      color: theme.palette.text.primary,
      margin: "0 0 0px 0",
      textAlign: "end",
    },

    h6: {
      fontFamily: theme.direction === "ltr" ? EnglishFont : EnglishFont,
      fontWeight: "500",
      fontSize: "10px",
      color: theme.palette.text.primary,
      margin: "0 0 0px 0",
      textAlign: "end",
    },

    // span: {
    //   fontFamily: theme.direction === "ltr" ? EnglishFont : EnglishFont,
    //   fontWeight: "normal",
    //   color: theme.palette.colors.body,
    // },

    ".grecaptcha-badge": {
      visibility: "hidden",
    },
    ".css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed": {
      color: "#07A26A !important",
    },
    ".css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active": {
      color: "#1E70FC",
    },
    ".MuiOutlinedInput-root": {
      borderRadius: "10px !important",
      color: theme.palette.primary.main,
      // height: "48px",
      justifyContent: "center",
      "&:hover, &:focus": {
        borderColor: "#fff !important",
        borderWidth: "5px",
        cursor: "pointer",
        borderRadius: "10px",
      },
    },
    ".MuiInputBase-root": {
      color: theme.palette.primary.main,

      "&:hover, &:focus": {
        borderColor: "#fff",
        borderWidth: "5px",
        cursor: "pointer",
        borderRadius: "10px",
      },
    },
    ".MuiStepper-root ": {
      width: "100%",
    },
    ".MuiStepLabel-label": {
      fontFamily: theme.direction === "ltr" ? EnglishFont : EnglishFont,
      fontWeight: "700",
      fontSize: "16px",
      color: theme.palette.text.primary,
      margin: "0 0 0px 0",
      textAlign: "end",
    },
    ".MuiDrawer-paper": {
      backgroundColor: theme.palette.common.black,
    },
    ".MuiBackdrop-root": {
      backgroundColor: alpha(theme.palette.common.black, 0.75),
      backdropFilter: "blur(2px)",
    },
    ".MuiAutocomplete-listbox": {
      direction:
        theme.direction === "ltr" ? "rtl !important" : "ltr !important",
    },
    "::selection": {
      color: theme.palette.common.white,
      background: theme.palette.primary.main,
    },
    ".MuiButtonBase-root-MuiTab-root.Mui-selected": {
      color: "#1E70FC",
    },
    ".MuiTab-textColorPrimary": {
      color: "#1E70FC",
    },
    ".Mui-selected": {
      color: "#1E70FC",
    },
    ".css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected": {
      color: "#1E70FC !important",
    },
    ".MuiButton-contained": {
      color: "#ffffff",
      boxShadow:
        "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
      backgroundColor: theme.palette.primary.main,
      fontWeight: "bold",
      fontFamily: theme.direction === "ltr" ? EnglishFont : EnglishFont,
      fontSize: theme.typography.pxToRem(16),
      maxHeight: "48px",
      minHeight: "48px",
      height: "48px !important",
      textTransform: "capitalize",
      padding: theme.spacing(1, 6, 1, 6),
      borderRadius: "10px",
      margin: 0,
    },
    ".MuiButton-outlined": {
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      backgroundColor: "#fff",
      fontWeight: "700",
      fontFamily: theme.direction === "ltr" ? EnglishFont : EnglishFont,
      fontSize: theme.typography.pxToRem(16),
      height: "48px !important",
      textTransform: "capitalize",
      padding: theme.spacing(1, 6, 1, 6),
      borderRadius: "10px",

      "&:hover, &:focus": {
        color: "#fff",
        backgroundColor: theme.palette.primary.main,
        boxShadow:
          "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
      },
    },
    ".MuiMenu-paper": {
      top: " 75px",
      width: "220px !important",
      backgroundColor: theme.palette.text.third,
    },
    ".MuiButton-contained:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.8,
    },
    ".MuiTooltip-tooltip": {
      fontSize: theme.typography.pxToRem(16),
    },
    ".MuiRadio-colorSecondary.Mui-checked": {
      color: `${theme.palette.primary.main} !important `,
    },
    ".page-content-wrapper": {
      width: "100%",
      whiteSpace: "normal",
      "& a": {
        wordBreak: "break-word",
      },
      "& pre": {
        whiteSpace: "pre-wrap",
        wordBreak: "break-word",
      },
    },
    ".mar-bottom-3": {
      marginBottom: theme.spacing(3),
    },
    ".scrollbar": {
      width: "1em",
      height: "1em",
    },

    "::-webkit-scrollbar": {
      width: "5px",
      height: "5px",
    },
    "::-webkit-scrollbar-track": {
      backgroundColor: "white !important",
      width: "5px",
      height: "5px",
    },
    "::-webkit-scrollbar-thumb": {
      backgroundColor: `${theme.palette.secondary.light} !important`,
      borderRadius: "10px",
      width: "5px",
      height: "5px",
    },
  },
  pageWrapper: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    minHeight: "100vh",
    maxWidth: "100%",
  },
  content: {
    display: "flex",
    flex: "1 1 auto",
    justifyContent: "center",
    background: "#F2F2F2",
    paddingTop: theme.spacing(14.5),
    [theme.breakpoints.down("md")]: {
      paddingTop: theme.spacing(9.5),
    },
  },
  content2: {
    flex: "1 1 auto",
    background: "#fff",
    paddingTop: theme.spacing(14.5),
    [theme.breakpoints.down("md")]: {
      paddingTop: theme.spacing(9.5),
    },
  },
});
