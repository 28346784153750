import React, { createContext } from "react";
// import { useLocation } from "react-router-dom";

const GeneralContext = createContext({});

const GeneralProvider = ({ children }: any) => {
  const [mobileMenuState, setMobileMenuState] = React.useState(false);
  const [selectedTab, setSelectedTab] = React.useState("/");
  const homeRef = React.useRef<any>(null);
  const aboutUs = React.useRef<any>(null);
  const contactUs = React.useRef<any>(null);

  // const location = useLocation();
  const executeScroll = (ref: any) => {
    switch (ref) {
      case "/":
        window.scrollTo({top: 0, left: 0, behavior: 'smooth',});
        break;
      case "/AboutUs":
        aboutUs.current?.scrollIntoView({ behavior: "smooth",inline: 'start' });
        break;
      case "/ContactUs":
        contactUs.current?.scrollIntoView({ behavior: "smooth",inline: 'start' });
        break;
    }
  };

  const toggleMobileMenuDrawer = (event: any, location: string) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setMobileMenuState(!mobileMenuState);
    setSelectedTab(location);
  };

  React.useEffect(() => {
    //@ts-ignore
    if (selectedTab !== "/Services") {
      executeScroll(selectedTab)
    }
  }, [selectedTab]);

  return (
    <GeneralContext.Provider
      value={{
        mobileMenuState,
        setMobileMenuState,
        toggleMobileMenuDrawer,
        selectedTab,
        homeRef,
        aboutUs,
        contactUs
      }}
    >
      {children}
    </GeneralContext.Provider>
  );
};
const GeneralConsumer = GeneralContext.Consumer;

export { GeneralProvider, GeneralConsumer,GeneralContext };
