import styled from 'styled-components';
import Typography from 'antd/es/typography';
export const TitleText = styled(Typography.Text)`
  font-family: Roboto;
  font-size: 11px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  margin-bottom: 4px;
  letter-spacing: normal;
  color: #000000;
`;
export const TimeText = styled(Typography.Text)`
  font-family: Roboto;
  font-size: 10px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  margin-bottom: 4px;
  letter-spacing: normal;
  color: #0040AC;
`;
export const MsgText = styled(Typography.Text)`
  font-family: Roboto;
  font-size: 11px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.09;
  margin-bottom: 4px;
  letter-spacing: normal;
  color: #979797;
`;
