import React from "react";
import { useStyles } from "./Styles";
const Footer = () => {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <span style={{ color: "#5A6474", marginRight: '5px'}}>© 2022 </span>
      <span style={{ color: "#000000", marginRight: '5px' }}> Exits, </span>
      <span style={{ color: "#5A6474" }}> All Rights Reserved </span>
    </footer>
  );
};
export default Footer;
