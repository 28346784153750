// import { AccountNotif, MemberNotif } from '../../../components/SvgIcons';
import { TitleText, TimeText } from "./styled";
import React, { FC, useCallback } from "react";
import { formatDateString } from "../utils";
import { useHistory } from "react-router-dom";
import Flex from "../../../components/flex";
import { NotificationInterface } from "./interface";

const OneNotification: FC<NotificationInterface> = ({
  Title,
  time,
  fullObject,
  onClick,
}): JSX.Element => {
  const { push } = useHistory();
  // const handleDate = React.useCallback(() => {
  //     let CurrentDate = Date.now();
  //     let DifDates = CurrentDate-time.getDate();
  // } , [time])

  return (
    <Flex
      padding="8px"
      margin="0 0 8px 0"
      radius="6px"
      column
      width="100%"
      cursor="pointer"
      onClick={() => onClick(fullObject)}
      background={fullObject?.isRead ? "#fff" : "#0000000D"}
    >
      {console.log(fullObject, "full")}
      <Flex>
        <Flex column width="100%">
          <TitleText children={Title} />
          <Flex width="100%" padding=" 0 15px 0 0 ">
            <TimeText children={formatDateString(time + "")} />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default React.memo(OneNotification);
