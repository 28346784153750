import styled from "@emotion/styled";

export const Wrapper = styled.div`
  border-radius: 8px;
  position: relative;
  border: 2px solid #1e70fc;
  background: var(--gray-50, #f9fafb);
  box-shadow: 0px 26px 85px 0px rgba(0, 64, 172, 0.1);
  display: flex;
  min-height: 850px;
  height: auto;
  flex-direction: column;
  padding: 2rem;
  flex: 1;
  max-width: 350px;
  min-width: 270px;
`;

export const Header = styled.h2`
  text-align: center;
  color: var(--COLUMBIA-BLUE, #1e70fc);
  font-size: 30px;
  font-weight: 600;
  line-height: 36px;
`;
export const PriceWrapper = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  gap: 5px;
  span {
    color: #6d767c;
    font-size: 24px;
    font-weight: 500;
    line-height: 32px; /* 133.333% */
    align-self: center;
  }
  h4 {
    color: var(--mai-colors-main-black, #000);

    font-size: 56px;

    font-weight: 800;
    line-height: 60px;
    letter-spacing: -1.4px;
  }
  p {
    align-self: center;
    font-size: 36px;
    margin: 0;
  }
`;
export const Body = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const BulletPoint = styled.li`
  margin: 10px 0;
  position: relative;
  display: flex;
  gap: 10px;
  p {
    margin: 0;
    color: var(--grey-scale-grey-2, #6d767c);
    font-size: 14px;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
    text-align: left;
  }
`;

export const Footer = styled.div`
  margin-top: auto;
  position: absolute;
  bottom: 10px;
  width: 80%;
  display: flex;
`;

export const Button = styled.button`
  display: flex;
  height: 48px;
  padding: 17px 25px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  background: var(--CERULEAN, #0040ac);
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  flex: 1;
`;
