import React, { useContext, useEffect } from "react";
import userContext from "../../../store/userContext/context";
import useApi from "../../../services/useApiFetching";
import { getFromStorage } from "../../../utils/localStorage";

const useGetNotif = () => {
  const { getRequest, putRequest } = useApi();
  const [loading, setLoading] = React.useState(false);
  const [loadingMore, setLoadingMore] = React.useState(false);
  const [NotificationVisible, setNotificationVisible] = React.useState(false);
  const [unReadView, setUnreadView] = React.useState(false);
  const [unReadNotifCount, setUnReadNotif] = React.useState(0);
  const [data, setData] = React.useState<any>([]);
  const [skip, setSkip] = React.useState(25);

  const {
    userState: { token },
  } = useContext(userContext);

  const getAllNotif = React.useCallback(async () => {
    setLoading(true);

    const response = await getRequest("/notification", false);
    if (response.message === "List loaded successfully") {
      if (response.unreadCount > 0) setUnreadView(true);
      setData(response.data);
    } else setData([]);
    let count = 0;
    // response.map((item: any) => {
    //   if (item.isRead === false) {
    //     count++;
    //   }
    // });
    setUnReadNotif(count);
    // await readAllNotifications(token);
    setLoading(false);
    setSkip(50);
  }, [getRequest]);

  const handleReadNotif = React.useCallback(
    async (notifObj: any) => {
      const User = getFromStorage("@exitsUser");
      setLoading(true);
      await putRequest(
        `/notification/${notifObj._id}`,
        { isRead: notifObj.isRead },
        false,
        User.token
      );
    },
    [putRequest]
  );
  const getAllNotifWithSkipParam = React.useCallback(async () => {
    setLoadingMore(true);
    // @ts-ignore
    const response = await getNotifications({ token: token, skip: skip });
    setData(response);
    setLoadingMore(false);
  }, [setData, setLoadingMore, token, skip]);
  // React.useEffect(() => {
  //     getAllNotif();
  //    },[getAllNotif])

  const onScroll = React.useCallback(() => {
    setSkip((oldstate) => oldstate + 15);
    getAllNotifWithSkipParam();
  }, [setSkip, getAllNotifWithSkipParam]);

  useEffect(() => {
    console.log(token, 'token');
    
    getAllNotif();
  }, [token]);
  return {
    data,
    loading,
    onScroll,
    loadingMore,
    getAllNotif,
    handleReadNotif,
    unReadNotifCount,
    NotificationVisible,
    setNotificationVisible,
    unReadView
  };
};

export default useGetNotif;
