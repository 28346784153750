export const getFromStorage = (key: string) => {
  const reqKey: any = sessionStorage.getItem(key) || localStorage.getItem(key);
  // const data = reqKey;
  const data = JSON.parse(reqKey);
  return !!data && data;
};

export const addToStorage = (key: string, value: any) => {
  if (typeof value === "object") {
    value = JSON.stringify(value);
  }
  return sessionStorage.setItem(key, value);
};

export const clearStorage = () => sessionStorage.clear();

export const removeFromStorage = (key: string) => sessionStorage.removeItem(key);
