/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useContext, useMemo } from "react";
import { Grid, Toolbar, AppBar, Button } from "@material-ui/core";
import Popover from "@mui/material/Popover";
// import { Notification } from "../../SvgIcons";
import { useHistory } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { sm } from "../../../utils/ResponsiveUtility";
import MainNavigation from "../../MainNavigation";
import MobileMenu from "../../MobileMenu";
import { useStyles } from "./Styles";
import useGetNotification from "../hooks/useGetNotif";
import Logo from "../../Logo";
import useStore from "../../../store/userContext/context";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import Notification from "@material-ui/icons/Notifications";
import Flex from "../../../components/flex";
import LogOutIcon from "@material-ui/icons/ExitToApp";
import OneNotification from "../oneNotification";
// import { useFromStyles } from "../../../utils/FormStyles";
import { NamedRoutes } from "../../../routes/index";
import useLogoutRequest from "../../../services/GeneralHooks/logoutHook";

const Header = ({ location }: any) => {
  const { userState, setUserContext } = useContext(useStore);
  const {
    push,
    location: { pathname },
  } = useHistory();
  const { data, onScroll, getAllNotif, loading, handleReadNotif, unReadView } =
    useGetNotification();
  // const formClasses = useFromStyles();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [anchorNotificationEl, setAnchorNotificationEl] =
    React.useState<HTMLButtonElement | null>(null);
  const [width, setWidth] = React.useState(document.body.clientWidth);
  const [state, setState] = React.useState("");
  const Mobile = width <= sm;
  const DesktopAndUp = width > sm;

  const { handleLogOut } = useLogoutRequest();

  const handleClickProfile = (event: React.MouseEvent<HTMLButtonElement>) => {
    console.log("clicked");

    setAnchorEl(event.currentTarget);
  };
  const handleClickNotification = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    getAllNotif();
    setAnchorNotificationEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseNotification = () => {
    setAnchorNotificationEl(null);
  };
  const open = Boolean(anchorEl);
  const openNotification = Boolean(anchorNotificationEl);

  const id = open ? "simple-popover" : undefined;
  const NotificationId = openNotification ? "simple-popover" : undefined;
  const handleClick = useCallback((item) => {
    console.log(item, "clicked");
    if (item.notificationType === "company") {
      push(`/startup-details/${item.companyId}`);
      handleReadNotif(item);
      handleCloseNotification();
    }
    if (item.notificationType === "acquisition") {
      push(`/acquisition/${item.companyId}`);
      handleReadNotif(item);
      handleCloseNotification();
    }
  }, []);
  const HandleNotificationView = React.useMemo(() => {
    if (loading) {
      return (
        <Flex justify="center" alignItems="center">
          <LoadingOutlined />
        </Flex>
      );
    } else if (data && data.length === 0)
      return (
        <>
          <img src="/emptyNotifications.svg" />
          {/* <p>No Recent Notification</p> */}
        </>
      );
    else {
      return (
        <Flex column padding="8px 0 0px 0">
          {/*            
        //   <List
        //     loading={loading}
        //     itemLayout="vertical"
        //     dataSource={NotifList}
        //     renderItem={(item: any) => (
        //       <OneNotif
        //         Title={item.title}
        //         message={item.msg}
        //         Icon={item.icon}
        //         time={item.createdAt}
        //       />
        //     )}
        //   />
          {/* 
       ))} */}
          {data.map((item: any) => (
            <>
              <OneNotification
                Title={item.title}
                time={item.createdAt}
                fullObject={item}
                onClick={() => handleClick(item)}
              />
              <Flex
                borderBottom="solid 1px #ebebeb"
                width="100%"
                margin="0 0 5px 0"
              />
            </>
          ))}
        </Flex>
      );
    }
  }, [data, loading, handleClick]);
  React.useEffect(() => {
    const getWidth = () => {
      setWidth(document.body.clientWidth);
    };
    const resizeHeaderOnScroll = () => {
      let distanceY = window.pageYOffset || document.documentElement.scrollTop;
      let shrinkOn = 50;

      if (distanceY > shrinkOn) {
        setState("shrink");
      } else {
        setState("");
      }
    };
    window.addEventListener("scroll", resizeHeaderOnScroll);
    window.addEventListener("resize", getWidth);
    return () => {
      window.removeEventListener("scroll", resizeHeaderOnScroll);
      window.removeEventListener("resize", getWidth);
    };
  }, [width]);

  React.useEffect(() => {
    if (!userState.token) {
      // if (token) setUserContext((old) => ({ ...old, token: token }));
      // else setUserContext((old) => ({ ...old, token: "" }));
    }
  }, [userState.token]);

  const handleNavigate = useCallback(
    (path: string) => {
      return push(path);
    },
    [push]
  );

  const handleHeaderButton = useMemo(() => {
    if (userState?.token !== "") {
      return (
        <>
          <Button aria-describedby={id} onClick={handleClickNotification}>

            <div
              style={{
                width: "10px",
                height: "10px",
                borderRadius: "5px",
                backgroundColor: "#D22B2B",
                position: "absolute",
                right: "20px",
                top: "15px",
                visibility: unReadView? "visible" : "hidden"
              }}
            ></div>
            <Notification className={classes.iconWrapper} />
          </Button>

          <Button aria-describedby={id} onClick={handleClickProfile}>
            <AccountCircleIcon className={classes.iconWrapper} />
          </Button>
          <div>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <div className={classes.menuContainer}>
                <div className={classes.contactMenuContainer}>
                  {/* <AccountCircleIcon className={classes.iconWrapper} /> */}
                  <div className={classes.menuTextsContainer}>
                    <span className={classes.userNameMenuText}>
                      {userState.email}
                    </span>
                    <p className={classes.userTypeText}>{userState.userType}</p>
                  </div>
                </div>
                <div
                  className={classes.oneMenuContainer}
                  onClick={() => {
                    push(NamedRoutes.Profile);
                    handleClose();
                  }}
                >
                  <p className={classes.menuTitleText}>Profile</p>
                </div>
                <div className={classes.oneMenuContainer}>
                  <p
                    className={classes.menuTitleText}
                    onClick={() => {
                      push(NamedRoutes.FavoriteList);
                      handleClose();
                    }}
                  >
                    Favorite List
                  </p>
                </div>
                <div className={classes.oneMenuContainer}>
                  <p
                    className={classes.menuTitleText}
                    onClick={() => {
                      push(NamedRoutes.VDR);
                      handleClose();
                    }}
                  >
                    Access VDR
                  </p>
                </div>

                {userState.userType !== "Seller" && (
                  <div className={classes.oneMenuContainer}>
                    <p
                      onClick={() => {
                        push(NamedRoutes.subs);
                        handleClose();
                      }}
                      className={classes.menuTitleText}
                    >
                      Subscriptions
                    </p>
                  </div>
                )}

                <div className={classes.oneMenuContainer}>
                  <p
                    className={classes.menuTitleText}
                    onClick={() => {
                      handleLogOut();
                      handleClose();
                    }}
                  >
                    Logout
                  </p>
                </div>
              </div>
            </Popover>
          </div>

          <div>
            <Popover
              id={NotificationId}
              open={openNotification}
              anchorEl={anchorNotificationEl}
              onClose={handleCloseNotification}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <div className={classes.menuContainer}>
                <div style={{ padding: "15px" }}>{HandleNotificationView}</div>
              </div>
            </Popover>
          </div>
        </>
      );
    }
    return (
      <Button
        type="submit"
        variant="outlined"
        onClick={() =>
          handleNavigate(pathname === "/signin" ? "/signup" : "/signin")
        }
        children={pathname === "/signin" ? "Create Account" : "Login"}
        // className={styles.loginButton}
      />
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userState, push, pathname, open, openNotification, data, loading, unReadView]);
  const handleNavigationView = useMemo(() => {
    if (
      pathname !== "/signin" &&
      pathname !== "/signup" &&
      pathname !== "/forgot-password"
    ) {
      return <MainNavigation />;
    } else {
      return <></>;
    }
  }, [pathname]);
  return (
    <AppBar
      position="fixed"
      className={`${classes.header} ${location}`}
      id="header"
      elevation={0}
    >
      <Toolbar className={classes.headerToolbar}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          className={classes.headerGridContainer}
        >
          {DesktopAndUp && (
            <Grid item className={classes.headerDesktop}>
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Logo location={location} />
                {handleNavigationView}
              </div>
              {
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {handleHeaderButton}
                </div>
              }
            </Grid>
          )}
          {Mobile && (
            <div className={classes.mobileMenu}>
              <Logo location={location} />
              <MobileMenu />
            </div>
          )}
        </Grid>
      </Toolbar>
    </AppBar>
  );
};
export default Header;
